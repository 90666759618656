<template>
  <div class="service-intro">
    <el-divider content-position="left">コンセプト</el-divider>
    <div class="intro-content">
      <p>
        徹底した顧客目線でお客様の悩みを解決いたします。<br />
        株式会社ジャンプソフトでは、当たり前を当たり前だと思わない、常識を常識だと思わない精神で徹底して顧客目線にたってシステム開発を行っております。
      </p>
      <p>これにより、競合他社では実現出来ないような奇抜で斬新なアイディアで開発をされたソリューションをお客様にご提供することが可能となっております。</p>
      <p>お客様からは、最初戸惑ったものの今までにない体感でもう手放すことが出来ないといったお声を最も多くいただいております。</p>
      <p>これに甘えること無く、常に常識を疑ってお客様の求めるものを追求する姿勢でシステム開発に取り組んでいきます。</p>
    </div>
    <el-divider content-position="left">主なサービス内容</el-divider>
    <div class="intro-content">
      <p>株式会社ジャンプソフトでは、多岐に渡ってサービスを展開しております。</p>
      <ul>
        <li>金融、証券、銀行、保険及び公共事業関連システムの設計、構築、開発及び保守（Windows、Unix、Linux、Cobol、Oracle）</li>
        <li>Web系システムの設計、構築、開発及び保守（Apache、PHP、Javascript、Mysql）</li>
        <li>ジョブ制御系システムの設計、構築、開発及び保守（千手、JP1、Shell）</li>
        <li>クラウド系システムの設計、構築、開発及び保守（Amazon AWS）</li>
        <li>基盤サービスの設計、構築、開発及び保守</li>
      </ul>
    </div>
  </div>
</template>

<style lang="sass" scoped>
.service-intro
  max-width: 1200px
  margin: auto
  padding: 1px 24px
  :deep(.el-divider__text)
    font-weight: bold
    font-size: 18px
    user-select: none
  .intro-content
    margin: auto
    width: 100%
    max-width: 600px
    font-size: 13px
    padding-right: 24px
    color: #303133
</style>